<template>
  <div v-if="display === true">
    <van-divider
      dashed
      :style="{
        color: '#00cc99',
        borderColor: '#00cc99',
        margin: '10px 0px',
      }"
      >入学资料</van-divider
    >
    <van-row class="item">
      <van-col span="12" class="title">考试分数：</van-col>
      <van-col span="12" class="desc">{{ entrance.score }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">毕业学校：</van-col>
      <van-col span="12" class="desc">{{ entrance.school }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">户籍所在地：</van-col>
      <van-col span="12" class="desc">{{ entrance.regionName }}</van-col>
    </van-row>
    <van-row class="item">
      <van-col span="12" class="title">其他信息：</van-col>
      <van-col span="12" class="desc">{{ entrance.other }}</van-col>
    </van-row>
  </div>
</template>
<script>
import { Divider } from 'vant'
export default {
  components: {
    [Divider.name]: Divider
  },
  data () {
    return {
      display: false,
      entrance: {
        score: 0,
        school: '',
        regionName: '无',
        other: '无'
      }
    }
  },
  mounted: function () {
  },
  methods: {
    async retrieveEntrance (personCode) {
      var pd = { personCode: personCode, group: 'ETC', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_ETC_SCORE') {
            this.entrance.score = obj.value
            this.display = true
          } else if (element.key === 'PSN_ETC_SCHOOL') {
            this.entrance.school = obj.name
            this.display = true
          } else if (element.key === 'PSN_ETC_REGION') {
            this.entrance.regionName = obj.name
            this.display = true
          } else if (element.key === 'PSN_ETC_OTHER') {
            this.entrance.other = obj.text
            this.diaplsy = true
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  border-bottom: 1px dashed #eee;
  padding: 5px 0px;
  .title {
    text-align: right;
  }
  .desc {
    text-align: left;
  }
}
</style>
